import './styles.css';
import PartySocket from 'partysocket';

declare const PARTYKIT_HOST: string;

const characters = [
    {
        name: 'Spider-Man',
        universe: 'Marvel',
        image: 'https://cdn.marvel.com/u/prod/marvel/i/mg/9/30/538cd33e15ab7/standard_xlarge.jpg',
    },
    {
        name: 'Batman',
        universe: 'DC',
        image: 'https://static.dc.com/2023-02/Char_WhosWho_Batman_20190116_5c3fc4b40faf04.59002472.jpg?w=160',
    },
    {
        name: 'Iron Man',
        universe: 'Marvel',
        image: 'https://cdn.marvel.com/u/prod/marvel/i/mg/3/e0/661e9b6428e34/standard_xlarge.jpg',
    },
    {
        name: 'Wonder Woman',
        universe: 'DC',
        image: 'https://static.dc.com/2023-02/Char_WhosWho_WonderWoman_20190116_5c3fc6aa51d124.25659603.jpg?w=184',
    },
    {
        name: 'Captain America',
        universe: 'Marvel',
        image: 'https://cdn.marvel.com/u/prod/marvel/i/mg/3/50/537ba56d31087/standard_xlarge.jpg',
    },
    {
        name: 'Superman',
        universe: 'DC',
        image: 'https://static.dc.com/2023-02/Char_WhosWho_Superman_20190116_5c3fc71f524f38.28405711.jpg?w=160',
    },
];

// Let's append all the messages we get into this DOM element
const output = document.getElementById('app') as HTMLDivElement;

// Helper function to add a new line to the DOM
function add(text: string) {
    output.appendChild(document.createTextNode(text));
}

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
    host: PARTYKIT_HOST,
    room: 'marvel-dc-poll',
});

// Create poll UI
function createPollUI() {
    const resultsElement = document.createElement('div');
    resultsElement.id = 'results';
    resultsElement.style.marginTop = '20px';
    resultsElement.style.textAlign = 'center';
    output.appendChild(resultsElement);

    const pollElement = document.createElement('div');
    pollElement.id = 'poll';
    pollElement.style.display = 'flex';
    pollElement.style.flexWrap = 'wrap';
    pollElement.style.justifyContent = 'center';
    pollElement.style.gap = '20px';

    characters.forEach((char) => {
        const card = document.createElement('div');
        card.style.width = '30%';
        card.style.border = '1px solid #ccc';
        card.style.borderRadius = '10px';
        card.style.padding = '10px';
        card.style.textAlign = 'center';
        card.style.backgroundColor =
            char.universe === 'Marvel' ? '#9f0036' : '#d3ad1b';

        const img = document.createElement('img');
        img.src = char.image;
        img.alt = char.name;
        img.style.width = '100%';
        img.style.height = '200px';
        img.style.objectFit = 'cover';
        img.style.borderRadius = '5px';
        card.appendChild(img);

        const name = document.createElement('h3');
        name.textContent = char.name;
        card.appendChild(name);

        const universe = document.createElement('p');
        universe.textContent = char.universe;
        card.appendChild(universe);

        const button = document.createElement('button');
        button.textContent = 'Vote';
        button.onclick = () => vote(char.name);
        card.appendChild(button);

        pollElement.appendChild(card);
    });

    output.appendChild(pollElement);
}

// Update results
function updateResults(votes: Record<string, number>) {
    const resultsElement = document.getElementById('results');
    if (resultsElement) {
        resultsElement.innerHTML = Object.entries(votes)
            .map(([character, count]) => `${character}: ${count}`)
            .join('<br>');
    }
}

// Vote function
function vote(character: string) {
    const vote = {
        character,
        universe: characters.find((c) => c.name === character)?.universe,
    };
    conn.send(JSON.stringify(vote));
}

// You can even start sending messages before the connection is open!
conn.addEventListener('message', (event) => {
    const pollState = JSON.parse(event.data);
    updateResults(pollState.votes);
});

// Let's listen for when the connection opens
conn.addEventListener('open', () => {
    add('Connected to Marvel vs DC poll!');
    createPollUI();
});

// Make vote function available globally
(window as any).vote = vote;
